import React from 'react';

const SeniorF1ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des Sernior F1'
      id="64084ba8d71d5909d5d03894"
      src={"https://scorenco.com/widget/64084ba8d71d5909d5d03894/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorF1ProchainsMatchs;