import React from 'react';

const SeniorM2DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des Sernior M2'
      id="640848070a221e62104809e0"
      src={"https://scorenco.com/widget/640848070a221e62104809e0/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorM2DerniersResultats;