import './App.css';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Accueil from './pages/Accueil';
import Presentation from './pages/Presentation';
import Actualites from './pages/Actualites';
import Equipes from './pages/Equipes';
import Partenaires from './pages/Partenaires';
import Contact from './pages/Contact';
import Media from './pages/Media';
import Portail from './components/auth/Portail'
import Panel from './pages/Panel'



function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/actualites" element={<Actualites />} />
        <Route path="/media" element={<Media />} />
        <Route path="/equipes" element={<Equipes />} />
        <Route path="/partenaires" element={<Partenaires />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portail" element={<Portail />} />
        <Route path="/panel" element={<Panel />} />
      </Routes>
    </Router>
  );
}

export default App;
