import React from 'react'
import "./EquipesSelect.css"
import U15M1Classement from '../Scorenco/U15M1Classement'
import U15M1DerniersResultats from '../Scorenco/U15M1DerniersResultats'
import U15M1ProchainsMatchs from '../Scorenco/U15M1ProchainsMatchs'

function U15M1() {
  return (
    <>
    <div className='container-image-equipes' >
        <img src='img/equipes/U15.jpg' alt='U15M1'class='equipes-image'/>
    </div>
    <div className='container-info-equipes'>
        <div className='container-equipes-derniers-resultats'>
            <div className='titre-equipes-derniers-resultats'>
                <div><h2>Derniers résultats</h2></div>
            </div>
            <div className='scorenco-equipes-derniers-resultats'>
                <U15M1DerniersResultats />
            </div>
        </div>
        <div className='container-equipes-prochains-matchs'>
            <div className='titre-equipes-prochains-matchs'>
                <div><h2>Prochains matchs</h2></div>
            </div>
            <div className='scorenco-equipes-prochains-matchs'>
                <U15M1ProchainsMatchs />
            </div>
        </div>
        <div className='container-equipes-classement'>
            <div className='titre-equipes-classement'>
                <div><h2>Classement</h2></div>
            </div>
            <div className='scorenco-equipes-classement'>
                <U15M1Classement />
            </div>
        </div>
        
    </div>
      
    </>
  )
}

export default U15M1