import React from 'react';

const SeniorM1ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des Sernior M1'
      id="63ebb447bea1b16511b06a74"
      src={"https://scorenco.com/widget/63ebb447bea1b16511b06a74/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorM1ProchainsMatchs;