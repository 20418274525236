import React from 'react'
import "./Presentation.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ScrollToTop from "react-scroll-to-top";

export default function Presentation() {
  return (
    <>
      <Navbar />
      <ScrollToTop smooth color="#000000" />
      <div className='container-histo-presentation'>
        <div className='histo-presentation'>
          <div className='titre-histo-presentation'><h2>Histoire du club</h2></div>
          <div className='container-box-histo-presentation'>
            <div className='banner-histo-presentation'>
            </div>
            <div className='texte-histo-presentation'>
              <p>L'Union Sportive Castres Football est un club français <br />
                de football basé à Castres (département du Tarn, région Occitanie). <br />
                Depuis 2016, le club évolue en Régional 3 de la ligue de Midi-Pyrénées de football (D8). <br />
                En 1965, l’Étoile sportive castraise devient champion de Division Honneur du Midi devant Toulouse Fontaines Club. Le club accède ainsi au Championnat de France Amateur (CFA) dans le groupe Sud-Ouest. <br />
                En 1967, l’Étoile Sportive Castraise termine à la première place du groupe Sud-Ouest du Championnat de France Amateur (CFA). Mais ne peut accéder à la montée en Division 2 car ils terminent à la troisième et dernière place du groupe A de la phase finale derrière l’US Quevilly et le Gazélec Ajaccio. <br />
                En 1973, l’Étoile Sportive Castraise est reléguée en Division Honneur. Le club ne reviendra plus jamais au niveau national. <br />
                En juin 1998, l’Étoile Sportive Castraise s’associe avec l’AS Puech-Auriol pour former le Castres Football Club. <br />
                En 2014, le Castres Lameilhe Football Club et le Castres Football Club décident de fusionner avec la volonté de construire un club encore plus haut et plus fort. Il forme l'Union Sportive Castres Football.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='container-orga-presentation'>
        <div className='presentation-orga-administratif'>
          <div className='titre-presentation-orga-administratif'>
            <h2>Organigramme administratif</h2>
          </div>
          <div className='image-presentation-orga-administratif'>

          </div>
        </div>

        <div className='presentation-orga-sportif'>
          <div className='titre-presentation-orga-sportif'>
            <h2>Organigramme sportif</h2>
          </div>
          <div className='image-presentation-orga-sportif'>

          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
