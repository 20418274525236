import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import DeleteArticle from "./DeleteArticle";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Articles.css"

export default function Articles() {
  const [articles, setArticles] = useState([]);
  const [user] = useAuthState(auth);
  useEffect(() => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articles);
    });
  }, []);
  return (
    
    <div>
      {articles.length === 0 ? (
        <p>Aucun article trouvé !</p>
      ) : (
        articles.map(
          ({
            id,
            title,
            description,
            imageUrl,
            createdAt,
            userId,
          }) => (
            <div className="container-card-articles" key={id}>
              <div className="card-articles">
                <div className="">
                    <img
                      src={imageUrl}
                      className="image-media-articles"
                      alt="title"
                    />
                </div>
                <div className="">
                  <div className="">
                    <div className="">
                      {user && user.uid === userId && (
                        <DeleteArticle id={id} imageUrl={imageUrl} />
                      )}
                    </div>
                  </div>
                  <h3 className="titre-articles"><br /><hr></hr><br />{title}<br /></h3>
                  <p className="description-articles">{description}<br /><br /><hr></hr></p>
                  <p className="date-articles">Publié le {new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(createdAt.toDate()))}</p>
                </div>
              </div>
            </div>
          )
        )
      )}
    </div>
  );
}
