import React from 'react'
import "./Equipes.css"
import { useState } from "react";
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ScrollToTop from "react-scroll-to-top";
import EquipeChoice from "../components/equipes/EquipeChoice"
import SeniorM1 from '../components/equipes/SeniorM1';
import SeniorM2 from '../components/equipes/SeniorM2';
import SeniorF1 from '../components/equipes/SeniorF1';
import U18 from '../components/equipes/U18';
import U16 from '../components/equipes/U16';
import U15M1 from '../components/equipes/U15M1';
import U15M2 from '../components/equipes/U15M2';
import U14 from '../components/equipes/U14';
import EcoleDeFoot from '../components/equipes/EcoleDeFoot';

export default function Equipes() {

  const [selects,setSelects]= useState("Choisissez une équipe");

  return (
    <>
      <Navbar />
      <ScrollToTop smooth color="#000000" />
      <div className='container-equipes'>
        <div className='container-select-equipes'>
          <div className='equipes-titre'>
            <h2>{selects}</h2>
          </div>
          <select value={selects} onChange={e=>setSelects(e.target.value)} className="select-case">
                <option>- - -</option>
                <option>Senior M1</option>
                <option>Senior M2</option>
                <option>Senior F1</option>
                <option>U18</option>
                <option>U16</option>
                <option>U15 M1</option>
                <option>U15 M2</option>
                <option>U14</option>
                <option>École de foot</option>
              </select>
        </div>
        {selects === '- - -' ? <EquipeChoice /> : selects === 'Senior M1' ? <SeniorM1 /> : selects === 'Senior M2' ? <SeniorM2 /> : selects === 'Senior F1' ? <SeniorF1 /> : selects === 'U18' ? <U18 /> : selects === 'U16' ? <U16 /> : selects === 'U15 M1' ? <U15M1 /> : selects === 'U15 M2' ? <U15M2 /> : selects === 'U14' ? <U14 /> : selects === 'École de foot' ? <EcoleDeFoot /> : <EquipeChoice />}

        </div>
      <Footer />
    </>
  )
}
