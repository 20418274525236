import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Photos.css"
import DeletePhoto from "./DeletePhoto";

function Photos() {
    const [photos, setPhotos] = useState([]);
    const [user] = useAuthState(auth);
    
    useEffect(() => {
      const photoRef = collection(db, "Photos");
      const q = query(photoRef, orderBy("createdAt", "desc"));
      onSnapshot(q, (snapshot) => {
        const photos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPhotos(photos);
      });
    }, []);
    return (
      <>
      <div>
        {photos.length === 0 ? (
          <p>No photo found!</p>
        ) : (
          photos.map(
            ({
              id,
              imageUrl,
              createdAt,
              userId,
            }) => (
              <div className="container-card" key={id}>
                <div>
                  <div>
                      <img
                        src={imageUrl}
                        className="image-media"
                        alt=""
                      />
                  </div>
                  <div className="">
                    <div className="">
                      <div className="">
                        {user && user.uid === userId && (
                          <DeletePhoto id={id} imageUrl={imageUrl} />
                        )}
                      </div>
                        </div>
                    <p className="date-photos">Publié le {new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(createdAt.toDate()))}</p>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </div>
      </>
    );
}

export default Photos
