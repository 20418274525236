import React from 'react'
import "./EquipeChoice.css"

function EquipeChoice() {
  return (
    <div className='equipe-choice'>
        <div className='image-equipe-choice'>

        </div>
      
    </div>
  )
}

export default EquipeChoice
