import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";
import "./ContactForm.css"

export default function ContactForm() {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_cr0zdio', 'template_jd2d5ye', form.current, 'tCerjVhIcTO2WuHfQ')
        .then((result) => {
            toast("Message envoyé", { type: "success" });
        }, (error) => {
            toast("Erreur dans l'envoi du message", { type: "error" });
        });
    };

  return (
    <>
    <div className='container-contactform'>
        <form ref={form} onSubmit={sendEmail} className="contact-form">
            <div className='input-form-ct'>
                <label>Nom</label>
                <input type="text" name="name" className='input-form'/>
            </div>

            <div className='input-form-ct'>
                <label>Prénom</label>
                <input type="text" name="lastname" className='input-form'/>
            </div>

            <div className='input-form-ct'>
                <label>Email</label>
                <input type="email" name="email" className='input-form'/>
            </div>

            <div className='input-form-ct'>
                <label>Objet</label>
                <input type="text" name="object" className='input-form'/>
            </div>

            <div className='input-form-ct-area'>
                <label>Message</label>
                <textarea name="message" className='input-form-area'/>
            </div>

            <div className='input-form-ct'>
                <input type="submit" value="Envoyer" className='bouton-form-contact' />
            </div>
        </form>
    </div>
    </>
  )
}
