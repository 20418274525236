import React from 'react';

const Rematch = () => {
  return (
    <iframe
      title='Vidéo des actions des matchs'
      src={"https://www.rematch.tv/club/embed/bgt3q7fm7ejs72kgr6ng"}
      height="600"
      style={{width: "80%", frameborder: "no", scrolling: "yes"}}
    />
  );
}

export default Rematch;