import React from 'react'
import AddArticle from '../components/AddArticle'
import AddPhoto from '../components/AddPhoto'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./../firebaseConfig";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import AddPartenaire1 from '../components/AddPartenaire1';
import AddPartenaire2 from '../components/AddPartenaire2';
import AddPartenaire3 from '../components/AddPartenaire3';
import "./Panel.css"

function Panel() {
  const [user] = useAuthState(auth);
  return (
    <>
      <div className='container-panel'>
        <div className='container-titre-panel'>
          <Link to="/"><img src='./img/LogoUSCF.png' alt='logo'/></Link>
          <h1>Panneau d’administration</h1>
        </div>
        <div className='container-add'>
          <div className='container-add-article'>
            <AddArticle />
          </div>
          <div className='container-photo-partenaires'>
            <div className='container-add-photo'>
              <AddPhoto />
            </div>
            <div className='container-add-partenaires'>
              <div className='add-partenaires1'><AddPartenaire1 /></div>
              <div className='add-partenaires2'><AddPartenaire2 /></div>
              <div className='add-partenaires2'><AddPartenaire3 /></div>
            </div>
          </div>
        </div>

          <div className='container-bouton-panel'>
          {user && (
            <>
            
              <div className='article-bouton-panel'><Link to="/actualites"><button>Modifier les articles</button></Link></div>
              <div className='logout-boutton-panel'>
                  <>
                    <span className="text-logout-bouton">
                      Connecté avec {user.displayName || user.email}
                    </span>
                    <Link to="/"><button onClick={()=>{signOut(auth)}}>Se déconnecter</button></Link>
                  </>
              </div>
              <div className='article-bouton-panel'><Link to="/media"><button>Modifier les Photos</button></Link></div>
              <div className='article-bouton-panel'><Link to="/partenaires"><button>Modifier les Partenaires</button></Link></div>

              </>
              )}
          </div>
      </div>
    </>
  )
}

export default Panel
