import React from 'react';

const SeniorF1Classement = () => {
  return (
    <iframe
      title='le classement des Sernior F1'
      id="620e1d80e347c70a20597343"
      src={"https://scorenco.com/widget/620e1d80e347c70a20597343/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorF1Classement;