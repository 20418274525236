import React from 'react'
import "./Contact.css"
import ContactForm from "../components/ContactForm"
import Navbar from "../components/Navbar"
import ScrollToTop from "react-scroll-to-top";
import MapContact from '../components/MapContact';
import Footer from "../components/Footer"

export default function Contact() {
  return (
    <>
      <Navbar />
      <ScrollToTop smooth color="#000000" />


      <div className='container-titre-contact-form'>
        <div className='titre-contact-form'>
              <h2>Contact</h2>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>

      <div className='container-info-contact'>
          <div className='container-map-contact'>
            <div className='map-contact'>
              <MapContact />
            </div>
          </div>
          <div className='container-item-contact'>
            <div className='item-reseau-contact'>
              <div className='item-facebook-contact'>
              <a href='https://www.facebook.com/search/top?q=uscastresfootball'><img src='./img/facebook.png' alt='Logo du club'/></a>
              </div>
              <div className='item-instagram-contact'>
                <a href='https://www.instagram.com/uscastresfootball/'><img src='./img/instagram.png' alt='Logo du club'/></a>
              </div>

            </div>
            <div className='item-card-contact'>
                <div className='item-contact'>
                  <div className='logo-item-contact' id='logo-item-contact-adresse'/>
                  <div className='titre-item-contact'><h2>Adresse</h2></div>
                  <div className='contenu-item-contact'>2 Chemin de la Verdarié <br />81100 Castres</div>
                </div>

                <div className='item-contact'>
                <div className='logo-item-contact' id='logo-item-contact-telephone'/>
                <div className='titre-item-contact'><h2>Téléphone</h2></div>
                <div className='contenu-item-contact'>05 63 72 37 17</div>
                </div>

                <div className='item-contact'>
                <div className='logo-item-contact' id='logo-item-contact-email'/>
                <div className='titre-item-contact'><h2>Email</h2></div>
                <div className='contenu-item-contact'>contact@uscastresfootball.fr</div>
                </div>
            </div>
          </div>
      </div>
      <Footer />
    </>
  )
}
