import React from 'react'
import "./Accueil.css"
import Navbar from '../components/Navbar'
import {Link} from 'react-router-dom'
import DerniersResultats from '../components/Scorenco/DerniersResultats'
import ProchainsMatchs from '../components/Scorenco/ProchainsMatchs'
import ScrollToTop from "react-scroll-to-top";
import Footer from "../components/Footer"
import LastArticle from "../components/LastArticle"
import LastPhoto from "../components/LastPhoto"

function Accueil() {

  return (
    <>
      <Navbar />
      <ScrollToTop smooth color="#000000" />

      <div className='banner-container'>
        <div className='titre-banner'>
          <h1>Union Sportive Castres Football</h1>
        </div>
        <div className='button-banner'>
          <div className='container-button'>
          <Link to="/presentation">
            <button>Découvrir le club</button>
          </Link>
          </div>
        </div>
        <div className='partenaires-banner'>
          <div id='partenaire-1'/>
          <div id='partenaire-2'/>
          <div id='partenaire-3'/>
        </div>
      </div>

      <div className='container-info-accueil'>
        <div className='accueil-dernier-resultats'>
          <div className='titre-accueil-dernier-resultats'>
            <h2>Derniers résultats</h2>
          </div>
          <div className='scorenco-accueil-dernier-resultats'>
              <DerniersResultats />
          </div>
        </div>

        <div className='accueil-derniere-actualite'>
          <div className='titre-accueil-derniere-actualite'>
            <h2>Derniere actualité</h2>
          </div>
          <div className='container-lastarticle-accueil-prochains-matchs'>
            <div className='lastarticle-accueil-prochains-matchs'>
              <LastArticle />
            </div>
            <div className='container-bouton-lastarticle-accueil-prochains-matchs'>
              <div className='bouton-lastarticle'>
            <Link to="/actualites">
              <button>Consulter</button>
            </Link>
            </div>
            </div>
          </div>
        </div>

        <div className='accueil-prochains-matchs'>
          <div className='titre-accueil-prochains-matchs'>
            <h2>Prochains matchs</h2>
          </div>
          <div className='scorenco-accueil-prochains-matchs'>
              <ProchainsMatchs />
          </div>
        </div>
      </div>

      <div className='container-photos'>
      <div className='accueil-derniere-photo'>
          <div className='titre-accueil-derniere-photo'>
            <h2>Dernière photo</h2>
          </div>
          <div className='image-accueil-derniere-photo'>
            <LastPhoto />
          </div>
          <div className='container-bouton-lastphoto'>
              <div className='bouton-lastphotobutton'>
            <Link to="/media">
              <button>Consulter les photos</button>
            </Link>
            </div>
            </div>
        </div>

      </div>
      <Footer />
    </>
  )
}

export default Accueil
