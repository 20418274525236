import React from 'react';

const DerniersResultats = () => {

  return (
    <iframe
      title='les derniers résultats'
      id="63ebb1ec73ee0009dddcf8f3"
      src={"https://scorenco.com/widget/63ebb1ec73ee0009dddcf8f3/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default DerniersResultats;
