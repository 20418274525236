import React from 'react';

const U15M2DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des U15M2'
      id="64085fa50a221e6499e03ec2"
      src={"https://scorenco.com/widget/64085fa50a221e6499e03ec2/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U15M2DerniersResultats;