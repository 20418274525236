import React from 'react';

const SeniorM1DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des Sernior M1'
      id="63ebb6a1c25511650a7de730"
      src={"https://scorenco.com/widget/63ebb6a1c25511650a7de730/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorM1DerniersResultats;
