import React, { useState } from 'react'
import "./Navbar.css"
import {Link} from "react-router-dom";

function Navbar() {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className="navbar">
        <div className="logo-nav">
          <Link to="/"><img src='./img/LogoUSCF.png' alt='Logo du club'/></Link>
        </div>
        <ul className="nav-list">
            <li className="list-item">
                <Link to="/">Accueil</Link>
            </li>
            <li className="list-item">
              <Link to="/presentation">Présentation</Link>
            </li>
            <li className="list-item">
              <Link to="/actualites">Actualités</Link>
            </li>
            <li className="list-item">
              <Link to="/media">Média</Link>
            </li>
            <li className="list-item">
              <Link to="/equipes">Équipes</Link>
            </li>
            <li className="list-item">
              <Link to="/partenaires">Partenaires</Link>
            </li>
            <li className="list-item">
              <Link to="/contact">Contact</Link>
            </li>
        </ul>
        <div className='toggle-btn' onClick={toggleMenu}>
          <i className={isOpen ? '+++' : 'XXX'}><img id='icon-menu-b' src='./img/menu-b.png' alt='icone du menu'/></i>
        </div>
      </div>

      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
            <li className="list-item">
              <Link to="/">Accueil</Link>
            </li>
            <li className="list-item">
              <Link to="/presentation">Présentation</Link>
            </li>
            <li className="list-item">
              <Link to="/actualites">Actualités</Link>
            </li>
            <li className="list-item">
              <Link to="/media">Média</Link>
            </li>
            <li className="list-item">
              <Link to="/equipes">Équipes</Link>
            </li>
            <li className="list-item">
              <Link to="/partenaires">Partenaires</Link>
            </li>
            <li className="list-item">
              <Link to="/contact">Contact</Link>
            </li>
      </div>
      
    </>
  )
}

export default Navbar
