import React from 'react';

const U18ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des U18'
      id="64084ec3280a3909d40cc3a8"
      src={"https://scorenco.com/widget/64084ec3280a3909d40cc3a8/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U18ProchainsMatchs;