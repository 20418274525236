import React from 'react';

const U14DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des U14'
      id="6408633e33743f640d9099b5"
      src={"https://scorenco.com/widget/6408633e33743f640d9099b5/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U14DerniersResultats;