import React from 'react';

const U15M1Classement = () => {
  return (
    <iframe
      title='le classement des U15M1'
      id="620e16f96d14fe0a25866519"
      src={"https://scorenco.com/widget/620e16f96d14fe0a25866519/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U15M1Classement;