import React from "react";
import "../components/MapContact.css"

const MapComponent = () => {
  return (
    <div>
      <iframe
        className="map-resize"
        title="google-maps"
        src="https://maps.google.com/maps?width=400&amp;height=400&amp;hl=en&amp;q=02%20Chem.%20de%20la%20Verdari%C3%A9%20Castres+()&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      ></iframe>
    </div>
  );
};

export default MapComponent;
