import React from 'react';

const U15M1ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des U15M1'
      id="64085cc43bafc209d3b74399"
      src={"https://scorenco.com/widget/64085cc43bafc209d3b74399/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U15M1ProchainsMatchs;