import React from 'react'
import {Link} from 'react-router-dom'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./../firebaseConfig";
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import "./Footer.css"

function Footer() {
  const [user] = useAuthState(auth);
  const Navigate = useNavigate();
  const handlePortailPage = () =>{
    Navigate("/portail")
  }
  const handlePanelPage = () =>{
    Navigate("/panel")
  }
  return (
    <div className='container-footer'>
      <div className='footer-top'>
        <div className='adresse-footer'>
          <div className='container-img-adresse-footer'>
            <div className='img-adresse-footer'/>
          </div>
          <div className='text-adresse-footer'>
            <div className='titre-adresse-footer'>
              Adresse
            </div>
            <div className='contenu-adresse-footer'>
              2 chemin de la Verdarié, 81100 Castres
            </div>
          </div>
        </div>

        <div className='telephone-footer'>
        <div className='container-img-telephone-footer'>
            <div className='img-telephone-footer'/>
          </div>
          <div className='text-telephone-footer'>
            <div className='titre-telephone-footer'>
              Telephone
            </div>
            <div className='contenu-telephone-footer'>
              05 63 72 37 17
            </div>
          </div>
        </div>

        <div className='email-footer'>
        <div className='container-img-email-footer'>
            <div className='img-email-footer'/>
          </div>
          <div className='text-email-footer'>
            <div className='titre-email-footer'>
              E-mail
            </div>
            <div className='contenu-email-footer'>
              contact@uscastresfootball.fr
            </div>
          </div>
        </div>
      </div>


      <div className='footer-bottom'>
        <div className='container-partenaire-footer'>
          <div className='container-partenaire-1-footer'>
            <div className='partenaire-1-footer'/>
          </div>
          <div className='container-partenaire-2-footer'>
          <div className='partenaire-2-footer'/>
          </div>
          <div className='container-partenaire-3-footer'>
          <div className='partenaire-3-footer'/>
          </div>
        </div>
        <div className='container-info-footer'>
          <div className='titre-info-footer'>
            <h2>Union Sportive Castres Football</h2>
          </div>
          <div className='loi-info-footer'>
            <div className='cdt-util-info-footer'><a href="Conditions-d'utilisation.pdf">Conditions d'utilisation</a></div>
            <div className='cdt-men-leg-footer'><a href="Mentions-légales.pdf">Mentions légales</a></div>
          </div>
        </div>
        <div className='container-logo-footer'>
          <div className='logo-footer'>
          <Link to="/"><img src='./img/LogoUSCF.png' alt='logo du club'/></Link>
          </div>
        </div>

        <div className='container-item-footer'>
            <div className='container-bouton-item-footer'>
              <Link to="/contact">
                <button>Nous contacter</button>
              </Link>
            </div>
            <div className='container-portail-item-footer'>
             {!user ? ( 
                <button onClick={handlePortailPage}>Portail</button>
             ) : (
              <button onClick={handlePanelPage}>Portail</button>
             )}
            </div>
            <div>
              {user && (
                <div className='logout-footer'>
                  <span>
                    Connecté avec {user.displayName || user.email}
                  </span>
                  <Link to="/"><button onClick={()=>{signOut(auth)}} id="bouton-logout-footer">Se déconnecter</button></Link>
                </div>
              )}
            </div>
            <div className='container-copyright-item-footer'>Copyright © 2023 - uscastresfootball</div>
            <div className='container-thomas-item-footer'><a href='https://thomascerqueira.com/'>Site web crée par Thomas Cerqueira</a></div>
        </div>
        <div className='container-nav-footer'>

        <ul className="nav-list-footer">
            <li className="list-item-footer">
                <Link to="/">Accueil</Link>
            </li>
            <li className="list-item-footer">
              <Link to="/presentation">Présentation</Link>
            </li>
            <li className="list-item-footer">
              <Link to="/actualites">Actualités</Link>
            </li>
            <li className="list-item-footer">
              <Link to="/media">Média</Link>
            </li>
            <li className="list-item-footer">
              <Link to="/equipes">Équipes</Link>
            </li>
            <li className="list-item-footer">
              <Link to="/partenaires">Partenaires</Link>
            </li>
            <li className="list-item-footer">
              <Link to="/contact">Contact</Link>
            </li>
        </ul>

        </div>
      </div>
        
    </div>
  )
}

export default Footer
