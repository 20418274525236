import React from 'react';

const U16DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des U16'
      id="640851295f8ad309d0dd41ee"
      src={"https://scorenco.com/widget/640851295f8ad309d0dd41ee/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U16DerniersResultats;