import React from 'react'
import "./EquipesSelect.css"
import U18DerniersResultats from "../Scorenco/U18DerniersResultats"
import U18ProchainsMatchs from "../Scorenco/U18ProchainsMatchs"
import U18Classement from "../Scorenco/U18Classement"

function U18() {
  return (
    <>
    <div className='container-image-equipes' >
        <img src='img/equipes/U18.jpg' alt='U18'class='equipes-image'/>
    </div>
    <div className='container-info-equipes'>
        <div className='container-equipes-derniers-resultats'>
            <div className='titre-equipes-derniers-resultats'>
                <div><h2>Derniers résultats</h2></div>
            </div>
            <div className='scorenco-equipes-derniers-resultats'>
                <U18DerniersResultats />
            </div>
        </div>
        <div className='container-equipes-prochains-matchs'>
            <div className='titre-equipes-prochains-matchs'>
                <div><h2>Prochains matchs</h2></div>
            </div>
            <div className='scorenco-equipes-prochains-matchs'>
                <U18ProchainsMatchs />
            </div>
        </div>
        <div className='container-equipes-classement'>
            <div className='titre-equipes-classement'>
                <div><h2>Classement</h2></div>
            </div>
            <div className='scorenco-equipes-classement'>
                <U18Classement />
            </div>
        </div>
        
    </div>
      
    </>
  )
}

export default U18