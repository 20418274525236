import React from 'react'
import "./EquipesSelect.css"
import SeniorM2DerniersResultats from "../Scorenco/SeniorM2DerniersResultats"
import SeniorM2ProchainsMatchs from "../Scorenco/SeniorM2ProchainsMatchs"
import SeniorM2Classement from "../Scorenco/SeniorM2Classement"

function SeniorsM2() {
  return (
    <>
    <div className='container-image-equipes' >
        <img src='img/equipes/Seniors-M2.jpg' alt='Seniors M2'class='equipes-image'/>
    </div>
    <div className='container-info-equipes'>
        <div className='container-equipes-derniers-resultats'>
            <div className='titre-equipes-derniers-resultats'>
                <div><h2>Derniers résultats</h2></div>
            </div>
            <div className='scorenco-equipes-derniers-resultats'>
                <SeniorM2DerniersResultats />
            </div>
        </div>
        <div className='container-equipes-prochains-matchs'>
            <div className='titre-equipes-prochains-matchs'>
                <div><h2>Prochains matchs</h2></div>
            </div>
            <div className='scorenco-equipes-prochains-matchs'>
                <SeniorM2ProchainsMatchs />
            </div>
        </div>
        <div className='container-equipes-classement'>
            <div className='titre-equipes-classement'>
                <div><h2>Classement</h2></div>
            </div>
            <div className='scorenco-equipes-classement'>
                <SeniorM2Classement />
            </div>
        </div>
        
    </div>
      
    </>
  )
}

export default SeniorsM2