import React from 'react';

const SeniorM2ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des Sernior M2'
      id="640849023bafc209ce867ffa"
      src={"https://scorenco.com/widget/640849023bafc209ce867ffa/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorM2ProchainsMatchs;