import React from 'react';

const U18DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des U18'
      id="64084dc5aee8f409dc07a984"
      src={"https://scorenco.com/widget/64084dc5aee8f409dc07a984/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U18DerniersResultats;