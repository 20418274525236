import React from 'react';

const U14Classement = () => {
  return (
    <iframe
      title='le classement des U14'
      id="620e1205d085445e112b3c5b"
      src={"https://scorenco.com/widget/620e1205d085445e112b3c5b/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U14Classement;