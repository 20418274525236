import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import "./Portail.css"

export default function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/panel");
    } catch (error) {
      toast(error.code, { type: "error" });
    }
  };
  return (
    <div className="container-form-login">
      <div className="container-titre-portail">
        <Link to="/"><img src='./img/LogoUSCF.png' alt='logo'/></Link>
        <h1>Portail de connexion</h1>
      </div>
      <div className="container-form-portail">
        <div className="form-portail">
        <div className="form-group">
          <label>Adresse e-mail</label>
          <input
            id="email-form-portail"
            type="email"
            className="form-control"
            placeholder="Entrez votre adresse e-mail"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <label>Mot de passe</label>
          <input
            id="mdp-form-portail"
            type="password"
            className="form-control"
            placeholder="Entrez votre mot de passe"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <br />
        <button className="bouton-form-login" onClick={handleLogin}>
          Se connecter
        </button>
      </div>
      </div>
      <div className="footer-portail">
        <div className='container-copyright-portail-footer'>Copyright © 2023 - uscastresfootball</div>
            <div className='container-thomas-portail-footer'><a href='https://thomascerqueira.com/'>Site web crée par Thomas Cerqueira</a></div>
        </div>
    </div>
  );
}
