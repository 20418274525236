import React from 'react'
import "./EquipesSelect.css"
import U15M2Classement from '../Scorenco/U15M2Classement'
import U15M2DerniersResultats from '../Scorenco/U15M2DerniersResultats'
import U15M2ProchainsMatchs from '../Scorenco/U15M2ProchainsMatchs'

function U15M2() {
  return (
    <>
    <div className='container-image-equipes' >
        <img src='img/equipes/U14.jpg' alt='U14/U15M2'class='equipes-image'/>
    </div>
    <div className='container-info-equipes'>
        <div className='container-equipes-derniers-resultats'>
            <div className='titre-equipes-derniers-resultats'>
                <div><h2>Derniers résultats</h2></div>
            </div>
            <div className='scorenco-equipes-derniers-resultats'>
                <U15M2DerniersResultats />
            </div>
        </div>
        <div className='container-equipes-prochains-matchs'>
            <div className='titre-equipes-prochains-matchs'>
                <div><h2>Prochains matchs</h2></div>
            </div>
            <div className='scorenco-equipes-prochains-matchs'>
                <U15M2ProchainsMatchs />
            </div>
        </div>
        <div className='container-equipes-classement'>
            <div className='titre-equipes-classement'>
                <div><h2>Classement</h2></div>
            </div>
            <div className='scorenco-equipes-classement'>
                <U15M2Classement />
            </div>
        </div>
        
    </div>
      
    </>
  )
}

export default U15M2