import React from 'react';

const U18Classement = () => {
  return (
    <iframe
      title='le classement des U18'
      id="64084fd30a221e67f847cd06"
      src={"https://scorenco.com/widget/64084fd30a221e67f847cd06/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U18Classement;