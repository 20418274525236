import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "./../firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import ProgressBar from "@ramonak/react-progress-bar";
import "./AddPhoto.css"

function AddPhotos() {
    const [user] = useAuthState(auth);
    const [formData, setFormData] = useState({
      image: "",
      createdAt: Timestamp.now().toDate(),
    });
  
    const [progress, setProgress] = useState(0);
  
    const handleImageChange = (e) => {
      setFormData({ ...formData, image: e.target.files[0] });
    };
  
    const handlePublish = () => {
      if (!formData.image) {
        alert("Please fill all the fields");
        return;
      }
  
      const storageRef = ref(
        storage,
        `/photos/${Date.now()}${formData.image.name}`
      );
  
      const uploadImage = uploadBytesResumable(storageRef, formData.image);
  
      uploadImage.on(
        "state_changed",
        (snapshot) => {
          const progressPercent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progressPercent);
        },
        (err) => {
          console.log(err);
        },
        () => {
          setFormData({
            image: "",
          });
  
          getDownloadURL(uploadImage.snapshot.ref).then((url) => {
            const photosRef = collection(db, "Photos");
            addDoc(photosRef, {
              imageUrl: url,
              createdAt: Timestamp.now().toDate(),
              createdBy:user.displayName,
              userId:user.uid,
            })
              .then(() => {
                toast("Photo ajouté avec succés", { type: "success" });
                setProgress(0);
              })
              .catch((err) => {
                toast("Erreur dans l'ajout de la photo", { type: "error" });
              });
          });
        }
      );
    };
  
    return (
      <div className="">
        {!user ? (
          <>
          </>
        ) : (
          <div className="container-form-photo">
              <div className="titre-form-photo">
              <h2>Photos</h2>
              </div>

          <div className="image-form-photo">
            <div className="form-group">
              {/* image */}
              <label htmlFor="">Image</label>
              <input
                id="image-form-photo"
                type="file"
                name="image"
                accept="image/*"
                className="form-control"
                onChange={(e) => handleImageChange(e)}
              />

          </div>
    
              {progress === 0 ? null :(
              <div className='progress'>
                <ProgressBar bgColor="#EE7F12" completed={`${progress}%`} />
              </div>
            )}
            <div className="bouton-form-photo">
            <button className='form-control' onClick={handlePublish}>Publier</button>
            </div>
            </div>
          </div>
        )}
      </div>
    );
}

export default AddPhotos
