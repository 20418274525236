import React from 'react';

const U15M2Classement = () => {
  return (
    <iframe
      title='le classement des U15M2'
      id="6408617d3bafc2636b09c83c"
      src={"https://scorenco.com/widget/6408617d3bafc2636b09c83c/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U15M2Classement;