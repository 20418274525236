import React from 'react'
import "./EcoleDeFoot.css"

function EcoleDeFoot() {
  return (
    <>
      <div className='container-image-equipes' >
        <img src='img/equipes/ecole-de-foot.jpg' alt='Ecole de foot'class='equipes-image'/>
      </div>

      <div className='container-image-ecole'>
        <div className='image-ecole-1-2'>
          <div className='image-ecole' id='ecole-1'/>
          <div className='image-ecole' id='ecole-2'/>
        </div>

        <div className='image-ecole-3-4'>
          <div className='image-ecole' id='ecole-3'/>
          <div className='image-ecole' id='ecole-4'/>
        </div>

        <div className='image-ecole-5-6'>
          <div className='image-ecole' id='ecole-5'/>
          <div className='image-ecole' id='ecole-6'/>
        </div>

        <div className='image-ecole-7-8'>
          <div className='image-ecole' id='ecole-7'/>
          <div className='image-ecole' id='ecole-8'/>
        </div>

        <div className='image-ecole-9'>
          <div className='image-ecole' id='ecole-9'/>
        </div>
      </div>
    </>
  )
}

export default EcoleDeFoot
