import { collection, onSnapshot, orderBy, query, limit } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import "./LastArticle.css"

export default function Article() {
  const [lastArticle, setLastArticle] = useState(null);

  useEffect(() => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"), limit(1));
    onSnapshot(q, (snapshot) => {
      snapshot.forEach((doc) => {
        setLastArticle({
          id: doc.id,
          ...doc.data(),
        });
      });
    });
  }, []);

  return (
    <div>
      {lastArticle ? (
        <div className="" key={lastArticle.id}>
          <div className="">
            <div className="">
              <img
                src={lastArticle.imageUrl}
                className="image-lastarticle"
                alt="title"
              />
            </div>
            <div className="container-lastarticle-item">
              <h3>{lastArticle.title}</h3>
              <p id="date-article">Publié le {new Intl.DateTimeFormat('fr-FR', {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(lastArticle.createdAt.toDate()))}</p>
            </div>
          </div>
        </div>
      ) : (
        <p>No articles found!</p>
      )}
    </div>
  );
}

