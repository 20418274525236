import React from 'react'
import Navbar from "../components/Navbar"
import Articles from "../components/Articles"
import "./Actualites.css"
import Footer from "../components/Footer"
import ScrollToTop from "react-scroll-to-top";

export default function Actualites() {
  return (
    <>
      <Navbar />
      <ScrollToTop smooth color="#000000" />
        <div className='container-newsletter'>
          <div>
            <a href='Newsletter.pdf' id='newsletter-bouton'>Télécharger la newsletter</a>
            </div>
        </div>

        <div className='container-article'>
          <div className='article-titre'>
            <h2>Articles</h2>
          </div>
          <div>
            <Articles />
          </div>
        </div>
      <Footer />
    </>
  )
}
