import React from 'react';

const SeniorF1DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des Sernior F1'
      id="64084a83280a3909d40cc385"
      src={"https://scorenco.com/widget/64084a83280a3909d40cc385/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorF1DerniersResultats;