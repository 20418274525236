import React from 'react';

const U16ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des U16'
      id="64085225aee8f409db055200"
      src={"https://scorenco.com/widget/64085225aee8f409db055200/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U16ProchainsMatchs;