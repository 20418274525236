import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Partenairev1.css"
import { Link } from "react-router-dom"
import DeletePartenaire1 from "./DeletePartenaire1";

function Partenaire1() {
    const [partenaires1, setPartenaires1] = useState([]);
    const [user] = useAuthState(auth);
    
    useEffect(() => {
      const partenaire1Ref = collection(db, "Partenaires1");
      const q = query(partenaire1Ref, orderBy("createdAt"));
      onSnapshot(q, (snapshot) => {
        const partenaires1 = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPartenaires1(partenaires1);
      });
    }, []);
    return (
      <>
      <div>
        {partenaires1.length === 0 ? (
          <p>No partenaire found!</p>
        ) : (
          partenaires1.map(
            ({
              id,
              imageUrl,
              imageLink,
              userId,
            }) => (
              <div className="container-card-partenaire" key={id}>
                <div>
                  <div>
                    <Link to={`${imageLink}`}>
                    <img
                      src={imageUrl}
                      alt=""
                      className="image-media1"
                    />
                    </Link>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="">
                        {user && user.uid === userId && (
                          <DeletePartenaire1 id={id} imageUrl={imageUrl} />
                        )}
                      </div>
                        </div>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </div>
      </>
    );
}

export default Partenaire1
