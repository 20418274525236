import React from 'react';

const U16Classement = () => {
  return (
    <iframe
      title='le classement des U16'
      id="6408530733743f61db0eab2b"
      src={"https://scorenco.com/widget/6408530733743f61db0eab2b/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U16Classement;