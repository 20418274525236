import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "./../firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import "./AddArticle.css"

export default function AddArticle() {
  const [user] = useAuthState(auth);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (!formData.title || !formData.description || !formData.image) {
      alert("Please fill all the fields");
      return;
    }

    const storageRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Articles");
          addDoc(articleRef, {
            title: formData.title,
            description: formData.description,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),
            createdBy:user.displayName,
            userId:user.uid
          })
            .then(() => {
              toast("Article ajouté avec succés", { type: "success" });
              setProgress(0);
            })
            .catch((err) => {
              toast("Erreur dans l'ajout de l'article", { type: "error" });
            });
        });
      }
    );
  };

  return (
    <div>
      {!user ? (
        <>
          <h2 id="connectez-panel">
            <Link to="/portail">Connectez-vous pour avoir accès au panneau d'administration</Link>
          </h2>
        </>
      ) : (
        <div className="container-form-article">
          <div className="titre-form-article">
          <h2>Articles</h2>
          </div>
          <div className="form-article">
            <div className="form-group">
              <label htmlFor="">Titre</label>
              <input
                id="titre-form-article"
                type="text"
                name="title"
                className="form-control"
                value={formData.title}
                onChange={(e) => handleChange(e)}
              />
            </div>

            {/* description */}
            <div className="form-group">
            <label htmlFor="">Description</label>
            <textarea
              id="description-form-article"
              name="description"
              className="form-control"
              value={formData.description}
              onChange={(e) => handleChange(e)}
            />
            </div>

            {/* image */}
            <div className="form-group">
            <label htmlFor="">Image</label>
            <input
              id="image-form-article"
              type="file"
              name="image"
              accept="image/*"
              className="form-control"
              onChange={(e) => handleImageChange(e)}
            />
            </div>

              {progress === 0 ? null :(
              <div className='progress'>
                <ProgressBar bgColor="#EE7F12" completed={`${progress}%`} />
              </div>
            )}

            <button className='form-control' onClick={handlePublish}>Publier</button>
          </div>
        </div>
      )}
    </div>
  );
}
