import React from 'react';

const U14ProchainsMatchs = () => {
  return (
    <iframe
      title='les prochains matchs des U14'
      id="64086378bf50050aafe0c43b"
      src={"https://scorenco.com/widget/64086378bf50050aafe0c43b/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U14ProchainsMatchs;