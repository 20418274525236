import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Partenairev2.css"
import { Link } from "react-router-dom"
import DeletePartenaire2 from "./DeletePartenaire2";

function Partenaire2() {
    const [partenaires2, setPartenaires2] = useState([]);
    const [user] = useAuthState(auth);
    
    useEffect(() => {
      const partenaire2Ref = collection(db, "Partenaires2");
      const q = query(partenaire2Ref, orderBy("createdAt"));
      onSnapshot(q, (snapshot) => {
        const partenaires2 = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPartenaires2(partenaires2);
      });
    }, []);
    return (
      <>
      <div>
        {partenaires2.length === 0 ? (
          <p>No partenaire found!</p>
        ) : (
          partenaires2.map(
            ({
              id,
              imageUrl,
              imageLink,
              userId,
            }) => (
              <div className="container-card-partenaire" key={id}>
                <div>
                  <div>
                    <Link to={`${imageLink}`}>
                    <img
                      src={imageUrl}
                      alt=""
                      className="image-media2"
                    />
                    </Link>
                  </div>
                  <div className="">
                    <div className="">
                      <div className="">
                        {user && user.uid === userId && (
                          <DeletePartenaire2 id={id} imageUrl={imageUrl} />
                        )}
                      </div>
                        </div>
                  </div>
                </div>
              </div>
            )
          )
        )}
      </div>
      </>
    );
}

export default Partenaire2
