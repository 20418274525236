import React from 'react';

const U15M1DerniersResultats = () => {
  return (
    <iframe
      title='les derniers résultats des U15M1'
      id="640859c6aee8f409dc07a9e3"
      src={"https://scorenco.com/widget/640859c6aee8f409dc07a9e3/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default U15M1DerniersResultats;