import React from 'react'

function ProchainsMatchs() {
  return (
    <iframe
      title='les prochains matchs'
      id="63f47388c0a39f6528132e34"
      src={"https://scorenco.com/widget/63f47388c0a39f6528132e34/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  )
}

export default ProchainsMatchs
