import React from 'react';

const SeniorM1Classement = () => {
  return (
    <iframe
      title='le classement des Sernior M1'
      id="620e1aead085440a12125d20"
      src={"https://scorenco.com/widget/620e1aead085440a12125d20/"}
      height="500"
      style={{ display: "block", width: "100%", overflow: "auto", margin: "auto", borderWidth: "0px" }}
    />
  );
}

export default SeniorM1Classement;