import React from 'react'
import "./Partenaires.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ScrollToTop from "react-scroll-to-top";
import Partenairev1 from '../components/Partenairev1';
import Partenairev2 from '../components/Partenairev2';
import Partenairev3 from '../components/Partenairev3';

export default function Partenaires() {
  return (
    <>
      <Navbar />
      <ScrollToTop smooth color="#000000" />
      <div className='container-partenaires'>
        <div className='titre-partenaires'>
          <h2>Partenaires</h2>
        </div>

        <div className='item-partenaires'>
          <div className='i1-partenaires'><Partenairev1 /></div>
          <div className='i2-partenaires'><Partenairev2 /></div>
          <div className='i3-partenaires'><Partenairev3 /></div>
        </div>

      </div>
      <Footer />
    </>
  )
}
