import React from 'react'
import Photos from "../components/Photos"
import "./Media.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ScrollToTop from "react-scroll-to-top";
import Rematch from "../components/Rematch"

function Media() {
  return (
  <>
  <Navbar />
  <ScrollToTop smooth color="#000000" />
  <div className='container-action-match'>
    <div className='action-match-titre'>
      <h2>Actions de match</h2>
    </div>
    
    <Rematch />

  </div>
  <div className='container-photo'>
    <div className='photo-titre'>
      <h2>Galerie photos</h2>
    </div>
      <div>
          <Photos />
      </div>
  </div>
  <Footer />
  </>
  )
}

export default Media
